import { graphql, useStaticQuery } from 'gatsby';
import { Video } from '../types';
import { getYouTubeID } from '../utils';

export type QueryResponse = {
  allWpMediaLink: {
    edges: {
      node: {
        title: string;
        date: string;
        acf: {
          mediaLink: string;
          mediaDescription: string;
        };
      };
    }[];
  };
};

export const useMediaLinksQuery = (): Video[] => {
  const { allWpMediaLink } = useStaticQuery<QueryResponse>(graphql`
    query MediaLinksQuery {
      allWpMediaLink {
        edges {
          node {
            title
            date(formatString: "YYYY.MM.DD")
            acf {
              mediaLink
              mediaDescription
            }
          }
        }
      }
    }
  `);

  return allWpMediaLink.edges.map(({ node }) => {
    const {
      title,
      date,
      acf: { mediaLink, mediaDescription },
    } = node;
    return {
      title,
      date,
      id: getYouTubeID(mediaLink),
      description: mediaDescription,
    };
  });
};
