import React from 'react';
import { Box } from 'rebass/styled-components';
import { useMediaLinksQuery } from '../queries/useMediaLinksQuery';
import { PageHeading, VideoGrid } from '../components';
import Layout from '../components/Layout';
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const Video = (pageContext: any) => {
  const allWpMediaLink = pageContext.data.allWpMediaLink;
  const videos = useMediaLinksQuery();

  return (
    <Layout>
      {allWpMediaLink.edges.map( (edge : any) => {
        <Seo post={edge.node} />
      })}

      <Box variant="container">
        <PageHeading subTitle="Video">影音分享</PageHeading>
        <VideoGrid videos={videos} />
      </Box>
    </Layout>
  );
};

export default Video;

export const pageQuery = graphql`
  query GET_PAGE_MEDIALINK {
    allWpMediaLink {
      edges {
        node {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  }
`;